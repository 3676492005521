import { TogglerSectionProps } from "./interface.";
import styles from "./styles.module.scss";
import { FC } from "react";
import { Button, duration } from "@mui/material";
import SettingsSVG from "../../../../shared/icons/SettingSVG";
import React from "react";
import { useSpring, config, animated } from "@react-spring/web";

const SECTIONBUTTONS = [
  { children: "Еда", value: 0 },
  { children: "Услуги", value: 1 },
  { children: "Товары", value: 2 },
];
const TogglerSection: FC<TogglerSectionProps> = ({
  onClickModalFilterBtn,
  onClickToggleButton,
  activeToggleValue,
}) => {
  const togglerStyleProps = useSpring({
    from: { opacity: 0 }, // Start offscreen, faded
    to: { opacity: 1 }, // Animate to normal
    config: {
      duration: 250,
    },
  });

  const settingButtonStyleProps = useSpring({
    from: { opacity: 0 }, // Start offscreen, faded
    to: { opacity: 1 }, // Animate to normal
    config: {
      duration: 250,
    },
  });

  const SettingButton = animated(Button);

  return (
    <div className={styles.top}>
      <animated.div style={togglerStyleProps} className={styles.togglerSect}>
        {SECTIONBUTTONS.map((el) => {
          return (
            <Button
              key={el.value}
              className={`${activeToggleValue === el.value && styles.active}`}
              onClick={(e) => {
                onClickToggleButton(e, el.value);
              }}
              variant="text"
            >
              {el.children}
            </Button>
          );
        })}
      </animated.div>

      <SettingButton
        style={settingButtonStyleProps}
        onClick={onClickModalFilterBtn}
        className={styles.settingBtn}
      >
        <SettingsSVG height={14} width={14} fill={"var(--input-color)"} />
      </SettingButton>
    </div>
  );
};

export default React.memo(TogglerSection, (pp, np) => {
  return np.activeToggleValue === pp.activeToggleValue;
});
