import { FC } from "react";
import styles from "./styles.module.scss";
import { HallInfoProps } from "../../../models/HallsCard";
import CardTopInfoBlock from "../../../../../components/HallCard/CardTopInfoBlock/CardTopInfoBlock";

const HallInfo: FC<HallInfoProps> = ({ isFree, capacity }) => {
  return (
    <div className={styles.info}>
      <CardTopInfoBlock>{capacity} чел.</CardTopInfoBlock>

      <CardTopInfoBlock className={isFree ? styles.free : styles.notFree}>
        {isFree ? "СВОБОДНО" : "ЗАНЯТО"}
      </CardTopInfoBlock>
    </div>
  );
};

export default HallInfo;
