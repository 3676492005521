import { FC, memo } from "react";
import { BaseComponentInterface } from "../../../../shared/models/BaseComponentInterface";
import { useSpring, animated } from "@react-spring/web";
import SectionHeader from "../../../../components/SectionHeader/SectionHeader";

const AnimatedHeader: FC<BaseComponentInterface> = ({ children }) => {
  // Определяем начальные значения opacity и scale
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 300, decay: 300 }, // Настройки анимации
  }));

  const AnimatedHeaderComponent = animated(SectionHeader);

  return (
    <animated.div style={{ opacity, scale }}>
      <SectionHeader>{children}</SectionHeader>
    </animated.div>
  );
};

export default memo(AnimatedHeader);
