import { BaseComponentButtonInterface } from "../../shared/models/BaseComponentInterface";
import { FC } from "react";
import styles from "./styles.module.scss";
import { Button, ButtonProps } from "@mui/material";
const FormSubmitButton: FC<ButtonProps> = ({
  onClick,
  children,
  className,
  type = "submit",
  disabled = false,
}) => {
  return (
    <Button
      type={type}
      className={`${styles.button} ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{children}</span>
    </Button>
  );
};

export default FormSubmitButton;
