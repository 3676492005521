import Navbar from "../../Navbar/Navbar";
import MainHeader from "../main/MainHeader";
import styles from "./styles.module.scss";

const HeaderWithNavbar = () => {
  return (
    <MainHeader className={styles.container}>
      <Navbar />
    </MainHeader>
  );
};

export default HeaderWithNavbar;
