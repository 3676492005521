import { Card, CardMedia } from "@mui/material";
import styles from "./styles.module.scss";
import contIMG from "../../../../../shared/assets/content.png";

import { HistoryCardProps } from "./interface";
import { FC } from "react";
import HistoryCardInfo from "./Info/Info";
import HistoryCardName from "./Name/Name";
import HallCard from "../../../../../components/HallCard/HallCard";
import CardTopInfoBlock from "../../../../../components/HallCard/CardTopInfoBlock/CardTopInfoBlock";
import HallCardName from "../../../../../components/HallCard/CardName/HallCardName";
import { transformPathToPhoto } from "../../../../../shared/functions/transformPathToPhoto";
const HistoryCard: FC<HistoryCardProps> = ({ capacity, date, name, photo }) => {
  return (
    <HallCard
      className={styles.card}
      image={photo}
      mediaChild={
        <>
          <HistoryCardInfo capacity={capacity} date={date} photo={transformPathToPhoto(photo)} />

          <HallCardName name={name} />
        </>
      }
    />
  );
};

export default HistoryCard;
