import { Swiper, SwiperSlide } from "swiper/react";
import bgImage from "../../../../shared/assets/BG.webp";
import styles from "./styles.module.scss";

import { useSpring, animated } from "@react-spring/web";

// Import Swiper styles
import "swiper/css";
import { FC } from "react";
import { HallImagesProps } from "./interface";
import { transformPathToPhoto } from "../../../../shared/functions/transformPathToPhoto";
const HallImages: FC<HallImagesProps> = ({ images }) => {
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 500 }, // Настройки анимации
  }));

  return (
    <animated.div style={{ opacity, scale }}>
      <Swiper
        className={styles.swiper}
        style={{ width: "100%", overflow: "hidden" }}
        initialSlide={2}
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={15}
        parallax={true}
      >
        {images &&
          Array.isArray(images) &&
          images?.map((el, index) => {
            return (
              <SwiperSlide key={index} className={styles.slide}>
                <img src={transformPathToPhoto(el)} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </animated.div>
  );
};

export default HallImages;
