import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL_API } from "../shared/constants/AppOptions";
import { SendPhoneNumReqInfo } from "./models/activateAPIModel";

export const activateApi = createApi({
  reducerPath: "myApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL_API}` }), // Базовый URL вашего API
  endpoints: (builder) => ({
    sendPhoneNumberData: builder.mutation<SendPhoneNumReqInfo, string>({
      query: (formData) => ({
        url: "/sms/send_sms/", // Эндпоинт вашего API для отправки формы
        method: "POST",
        body: { phone_number: formData }, // Данные формы
      }),
    }),

    sendCodeData: builder.mutation({
      query: (formData) => ({
        url: "/sms/send_sms/", // Эндпоинт вашего API для отправки формы
        method: "POST",
        body: formData, // Данные формы
      }),
    }),
  }),
});

export const { useSendPhoneNumberDataMutation } = activateApi;
