import { FC } from "react";
import { BaseComponentInterface } from "../../../../shared/models/BaseComponentInterface";
import { animated, useSpring } from "@react-spring/web";

interface AnimatedCardProps extends BaseComponentInterface {
  startAnimation?: boolean;
}
const AnimatedCard: FC<AnimatedCardProps> = ({
  className,
  children,
  startAnimation = false,
}) => {
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 300, decay: 300 }, // Настройки анимации
  }));

  return (
    <animated.div style={startAnimation ? { opacity, scale } : undefined}>
      {children}
    </animated.div>
  );
};

export default AnimatedCard;
