import { FC } from "react";
import styles from "./styles.module.scss";
import { HallInfoProps } from "./interface";
import HumanSVG from "../../../../shared/icons/HumanSVG";
import { RadioButtonChecked } from "@mui/icons-material";
import { useTheme } from "../../../../shared/hooks/useTheme";
import InfoListBlock from "../../../../components/InfoListBlock/InfoListBlock";
const HallInfo: FC<HallInfoProps> = ({ capacity, status }) => {
  const { theme } = useTheme();
  return (
    <InfoListBlock className={styles.container}>
      <>
        <li>
          <HumanSVG />{" "}
          <span>
            До <strong>{capacity}</strong> человек
          </span>
        </li>

        <li>
          <RadioButtonChecked /> {status}
        </li>
      </>
    </InfoListBlock>
  );
};

export default HallInfo;
