import { TimePickerProps } from "./interface";
import styles from "./styles.module.scss";
import { FC, useEffect, useState } from "react";

const hoursList = Array.from({ length: 24 }, (_, index) => index);
const minutesList = [0, 30];
const TimePicker: FC<TimePickerProps> = ({
  className,
  hours,
  minutes,
  onChangeMinutes,
  onChangeHours,
  openSelectClassName,
}) => {
  const [openSelect, setOpenSelect] = useState(false);

  useEffect(() => {
    const clickWindow = () => {
      setOpenSelect(false);
    };
    window.addEventListener("click", clickWindow);

    return () => {
      window.removeEventListener("click", clickWindow);
    };
  }, []);
  return (
    <div className={`${styles.container} ${className}`}>
      <input
        className={styles.input}
        value={`${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`}
        readOnly
        onClick={(e) => {
          e.stopPropagation();
          setOpenSelect(!openSelect);
        }}
      />

      <div
        className={`${styles.selectCont} ${openSelect && styles.open} ${
          openSelect && openSelectClassName
        }`}
      >
        <ul>
          {hoursList.map((num) => (
            <li
              onClick={(e) => {
                e.stopPropagation();
                onChangeHours(num);
              }}
              className={hours === num ? styles.active : ""}
              key={num}
            >
              {num}
            </li>
          ))}
        </ul>

        <ul>
          {minutesList.map((num) => {
            return (
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeMinutes(num);
                }}
                className={minutes === num ? styles.active : ""}
              >{`${num.toString().padStart(2, "0")}`}</li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TimePicker;
