import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../shared/hooks/reduxTypes";
import styles from "../styles.module.scss";
import SliderComponent from "../../../../../ui/Slider/Slider";
import { setPrice } from "../../../slice/MenuFilterSlice";
import { FC } from "react";

interface SlidePriceProps {
  onChange: (num: number) => void;
}
const SlidePrice: FC<SlidePriceProps> = ({ onChange }) => {
  const price = useAppSelector((state) => state.menuFilterForm.price);

  const dispatch = useAppDispatch();

  return (
    <SliderComponent
      className={styles.sliderPrice}
      min={0}
      max={10000}
      value={price}
      onChange={(e, v) => {
        if (typeof v === "number") {
          dispatch(setPrice(v));
          onChange(v);
        }
      }}
      name="Цена: "
      valueChild={
        <>{`${price === 0 ? "Не имеет значения" : `до ${price} руб`}`}</>
      }
    />
  );
};

export default SlidePrice;
