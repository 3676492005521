import { animated, useSpring } from "@react-spring/web";
import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";
import React from "react";
const AnimatedSubmitButton: FC<ButtonProps> = ({
  children,
  className,
  type,
  onClick,
  disabled = false,
}) => {
  const styleBottomButton = useSpring({
    from: {
      transform: "translateY(80px)",
    },
    to: {
      transform: "translateY(0)",
    },
    delay: 750,
    config: {
      duration: 250,
      decay: 20,
      friction: 300,
      tension: 250,
      mass: 3,
    },
  });

  const AnimatedButton = animated(Button);

  return (
    <AnimatedButton
      disabled={disabled}
      style={styleBottomButton}
      className={className}
      type={type}
      onClick={onClick}
    >
      {children}
    </AnimatedButton>
  );
};

export default React.memo(AnimatedSubmitButton);
