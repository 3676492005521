import { useTheme } from "../../shared/hooks/useTheme";
import { InfoListBlockProps } from "./interface";
import styles from "./styles.module.scss";
import { FC, useState } from "react";
import { useSpring } from "@react-spring/web";
import { animated } from "@react-spring/web";
import { useRef, useEffect } from "react";
const InfoListBlock: FC<InfoListBlockProps> = ({ className, children }) => {
  const { theme } = useTheme();

  const [firstRender, setFirstRender] = useState(false);

  // Определяем начальные значения opacity и scale
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 500 }, // Настройки анимации
  }));

  // Ссылка на элемент для определения его позиции
  const containerRef = useRef<HTMLDivElement | null>(null);

  const calculateVisibility = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Определяем высоту видимой части элемента
      const elementTopVisible = Math.max(0, rect.top); // Расстояние от верхней части окна до верхней части элемента
      const elementBottomVisible = Math.max(0, windowHeight - rect.bottom); // Расстояние от нижней части окна до нижней части элемента

      // Видимая высота элемента
      const visibleHeight =
        windowHeight - elementTopVisible - elementBottomVisible;
      const totalHeight = rect.height;

      // Рассчитываем процент видимости
      const visiblePercentage = (visibleHeight / totalHeight) * 100;

      return Math.max(0, Math.min(100, visiblePercentage)); // Убедимся, что значение в пределах 0-100
    }

    return 0;
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const visiblePercent = calculateVisibility();

      if (visiblePercent !== undefined) {
        api.start({
          opacity: Math.max(Math.min(visiblePercent / 100, 1), 0.5), // Увеличиваем opacity
          scale: Math.max(Math.min(visiblePercent / 100, 1), 0.5), // Уменьшаем scale по мере невидимости
        });
      }
    }
  };

  useEffect(() => {
    setFirstRender(true);
  });

  useEffect(() => {
    if (firstRender) {
      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [firstRender]);


  return (
    <>
      <animated.div
        style={{ opacity, scale, transition: "0s" }}
        ref={containerRef}
      >
        <div data-theme={theme} className={`${styles.container} ${className}`}>
          <ul className={styles.list}>{children}</ul>
        </div>
      </animated.div>
    </>
  );
};

export default InfoListBlock;
