import MainHeader from "../components/header/main/MainHeader";
import Navbar from "../components/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import MenuPage from "../pages/Menu/Menu";
import HallsPage from "../pages/Halls/HallsPage";
import OrderPage from "../pages/Order/Order";
import { usePathname } from "../shared/hooks/usePathname";
import HeaderWithNavbar from "../components/header/headerWithNavbar/HeaderWithNavbar";
import { useGetUserByTgIDQuery } from "../api/userAPI";
import { TG_ID } from "../shared/constants/AppOptions";
import { useTelegram } from "../shared/hooks/useTelegram";
import HallDescriptionPage from "../pages/HallDescription/HallDescription";
import { PrivateRoute } from "./PrivateRoute";
const MainRoutes = () => {
  const pathname = usePathname();
  const { webApp } = useTelegram();
  const {
    data: userData,
    isLoading: userLoadingData,
    isError: userErrorData,
  } = useGetUserByTgIDQuery(webApp?.initDataUnsafe?.user?.id);

  const func = () => {
    return (
      pathname === "/menu" ||
      pathname === "/" ||
      pathname === "/order" ||
      pathname.includes("hallInfo")
    );
  };

  return (
    <>
      {func() &&
        !userLoadingData &&
        !userErrorData &&
        userData?.phone_number !== null && <HeaderWithNavbar />}
      <Routes>
        <Route
          path="/menu"
          element={
            <PrivateRoute>
              <MenuPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HallsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/order"
          element={
            <PrivateRoute>
              <OrderPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/hallInfo/:id"
          element={
            <PrivateRoute>
              <HallDescriptionPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default MainRoutes;
