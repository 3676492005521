import { useTheme } from "../../../../shared/hooks/useTheme";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import styles from "../../styles.module.scss";
import {
  useGetUserByTgIDQuery,
  useLazyUpdateCartQuery,
} from "../../../../api/userAPI";
import { TG_ID, themeMapValue } from "../../../../shared/constants/AppOptions";
import { useTelegram } from "../../../../shared/hooks/useTelegram";

const ToggleTheme = () => {
  const { webApp } = useTelegram();
  const { theme, setValueTheme } = useTheme();
  const { data: userData } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
  );
  const [updateCart] = useLazyUpdateCartQuery();
  return (
    <ToggleButtonGroup
      onChange={(e, v) => {
        let val = v[0] as string;
        if (val === "light" || val === "dark") {
          setValueTheme(val);
          if (userData) {
            updateCart({
              user_id: userData?.id,
              user_theme: String(themeMapValue[val]),
            });
          }
        }
      }}
      className={styles.toggleTheme}
    >
      <ToggleButton
        className={`${theme === "light" && styles.active}`}
        value="light"
      >
        <span className={styles.btnText}>Светлая</span>
      </ToggleButton>
      <ToggleButton
        className={`${theme === "dark" && styles.active}`}
        value="dark"
      >
        <span className={styles.btnText}>Темная</span>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleTheme;
