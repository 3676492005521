import { HallCardProps } from "../../../models/HallsCard";
import { FC, useEffect, useRef } from "react";
import { animated, useSpring } from "@react-spring/web";
import HallCard from "../../Card/HallCard";
import { calculateVisibility } from "../../../../../shared/functions/calculateVisibility";
const AnimatedCard: FC<HallCardProps> = ({
  capacity,
  price,
  isFree,
  name,
  to,
  photo,
}) => {
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 500, decay: 3000 }, // Настройки анимации
  }));

  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <animated.div
      ref={containerRef}
      style={{ opacity, scale, transition: "0s" }}
    >
      <HallCard
        photo={photo}
        isFree
        price={price}
        name={name}
        to={to}
        capacity={capacity}
      />
    </animated.div>
  );
};

export default AnimatedCard;
