import React from "react";
import { useGetUserByTgIDQuery } from "../api/userAPI";
import { useTelegram } from "../shared/hooks/useTelegram";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { webApp } = useTelegram();
  const {
    data: userData,
    isLoading: userLoadingData,
    isError: userErrorData,
  } = useGetUserByTgIDQuery(webApp?.initDataUnsafe?.user?.id);

  return (
    <>
      {userData?.phone_number === null ? <Navigate to="/register" /> : children}
    </>
  );
};
