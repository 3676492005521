import { useGetUserByTgIDQuery } from "../../../../api/userAPI";
import { TG_ID } from "../../../../shared/constants/AppOptions";
import { useTheme } from "../../../../shared/hooks/useTheme";
import styles from "./styles.module.scss";
import { animated, useSpring, config } from "@react-spring/web";
import { useState } from "react";
import { saleValue } from "../../functions/saleValue";
import { useTelegram } from "../../../../shared/hooks/useTelegram";

const Sale = ({ value }: { value: number }) => {
  const { webApp } = useTelegram();
  const { theme } = useTheme();
  const [isMounted, setIsMounted] = useState(false); // Track mounting

  const descProps = useSpring({
    from: { opacity: 0, transform: "translateY(-20px)", scale: 0.9 }, // Start offscreen, faded, and smaller
    to: { opacity: 1, transform: "translateY(0px)", scale: 1 }, // Animate to normal
    config: config.gentle,
    delay: 100, // Delay for a little effect
    onRest: () => setIsMounted(true), // Set mounted when animation is complete
  });

  const valueProps = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" }, // Start offscreen, faded
    to: { opacity: 1, transform: "translateY(0px)" }, // Animate to normal
    config: config.gentle,
    delay: 100 + (isMounted ? 50 : 0), // Delay for a slight stagger
  });

  return (
    <>
      <animated.p style={descProps} className={styles.desc}>
        Увеличьте количество часов и получите скидку на все товары до 15%!
      </animated.p>

      <animated.p
        style={valueProps}
        data-theme={theme}
        className={styles.value}
      >
        Ваша скидка: {value}%
      </animated.p>
    </>
  );
};

export default Sale;
