import { Skeleton } from "@mui/material";
import styles from "./styles.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

const SkeletonLoader = () => {
  return (
    <>
      <div className={styles.container}>
        <Skeleton sx={{ height: 40 }} className={styles.name} />
      </div>

      <Swiper
        style={{ width: "100%", overflow: "hidden" }}
        initialSlide={1}
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={15}
        parallax={true}
        className={styles.loadingImage}
      >
        <SwiperSlide className={styles.loadingImageItem}>
          <Skeleton />
        </SwiperSlide>

        <SwiperSlide className={styles.loadingImageItem}>
          <Skeleton />
        </SwiperSlide>

        <SwiperSlide className={styles.loadingImageItem}>
          <Skeleton />
        </SwiperSlide>
      </Swiper>

      <div className={styles.container}>
        <Skeleton sx={{ height: 60, width: "100%" }} />

        <Skeleton sx={{ height: 104, width: "100%", marginTop: "30px" }} />
      </div>
    </>
  );
};

export default SkeletonLoader;
