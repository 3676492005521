import { HallCardProps } from "../../../../components/HallCard/interface";
import { FC, memo } from "react";
import HallCard from "../../../../components/HallCard/HallCard";
import { useSpring, animated } from "@react-spring/web";

const AnimatedCard: FC<HallCardProps> = ({
  className,
  image,
  mediaChild,
  children,
  cardMediaClassName,
}) => {
  // Определяем начальные значения opacity и scale
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 300 }, // Настройки анимации
  }));

  return (
    <animated.div style={{ opacity, scale }}>
      <HallCard
        className={className}
        image={image}
        mediaChild={mediaChild}
        cardMediaClassName={cardMediaClassName}
      >
        {children}
      </HallCard>
    </animated.div>
  );
};

export default memo(AnimatedCard);
