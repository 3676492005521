import { Button, Skeleton } from "@mui/material";
import React, { useState, useEffect, memo } from "react";
import styles from "./styles.module.scss";
import { CustomDatePickerProps } from "./interface";
import { useGetDateTimeQuery } from "../../api/userAPI";
import moment from "moment";

const dayOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  className,
  dateValue,
  onChangeDate,
  loader,
  classNameDatesContainer,
  classNameDate,
  classNameWeekDays
}) => {
  const { data: nowDate, isLoading, isError } = useGetDateTimeQuery(undefined);

  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const [dates, setDates] = useState<Date[]>([]);

  useEffect(() => {
    if (nowDate) {
      setCurrentDate(new Date(nowDate.datetime));
    }
  }, [nowDate]);

  useEffect(() => {
    generateDates();
  }, [currentDate]);

  const getStartOfWeek = (date: Date): Date => {
    return moment(date).startOf("isoWeek").toDate(); // ISO Week начинается с понедельника
  };

  const generateDates = () => {
    if (currentDate) {
      const startOfWeek = getStartOfWeek(currentDate);
      const newDates = [];

      for (let i = 0; i < 4; i++) {
        for (let j = 0; j < 7; j++) {
          const date = moment(startOfWeek)
            .add(i * 7 + j, "days")
            .startOf("day");
          newDates.push(date.toDate());
        }
      }

      setDates(newDates);
    }
  };

  const isDateDisabled = (date: Date) => {
    if (
      currentDate &&
      moment(date).isBefore(currentDate) &&
      moment(date).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")
    ) {
      return true;
    }

    return false;
  };

  const classNameForDate = (date: Date) => {
    const curDate = nowDate?.datetime ? moment(nowDate.datetime) : moment();

    return `${styles.date} ${
      moment(date).isBefore(curDate) &&
      moment(date).format("YYYY-MM-DD") !== moment(curDate).format("YYYY-MM-DD")
        ? styles.pastDate
        : ""
    } ${
      dateValue &&
      moment(date).format("YYYY-MM-DD") ===
        moment(dateValue).format("YYYY-MM-DD") &&
      styles.active
    }`;
  };

  if (isLoading || dates.length === 0) {
    return (
      <>
        {loader ? (
          loader
        ) : (
          <Skeleton
            sx={{
              width: "100%",
              height: 214,
              borderRadius: "15px",
              marginTop: "30px",
            }}
          />
        )}
      </>
    );
  }

  if (nowDate && dates.length > 0) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`${styles.container} ${className}`}
      >
        <div className={`${styles.weekDays} ${classNameWeekDays}`}>
          {dayOfWeek.map((day) => (
            <div className={styles.weekDay} key={day}>
              {day}
            </div>
          ))}
        </div>
        <div className={`${styles.dates} ${classNameDatesContainer}`}>
          {dates.map((date) => (
            <Button
              disabled={isDateDisabled(date)}
              className={`${classNameForDate(date)} ${classNameDate}`}
              key={date.toString()}
              onClick={(e) => {
                e.stopPropagation();
                onChangeDate(date);
              }}
              sx={{ width: 34, height: 34, minHeight: 0, minWidth: 0 }}
            >
              {date.getDate()}
            </Button>
          ))}
        </div>
      </div>
    );
  }

  return <></>;
};

export default memo(CustomDatePicker, (prevProps, nextProps) => {
  return prevProps.dateValue === nextProps.dateValue;
});
