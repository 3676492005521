import { FoodListItemApiModel } from "../../../../api/models/foodListApiModel";
import { BaseMenuListInterface } from "../../models/BaseListInterface";
import { FC, useEffect } from "react";
import MenuList from "../MenuList/MenuList";
import SkeletonCard from "../../../../ui/SkeletonCard/SkeletonCard";
import AnimatedCard from "../AnimatedCard/AnimatedCard";
import StyledMenuCard from "../../../../ui/MenuCard/MenuCard";
import {
  useGetUserByTgIDQuery,
  useLazyUpdateCartQuery,
  userApiSlice,
} from "../../../../api/userAPI";
import { TG_ID } from "../../../../shared/constants/AppOptions";
import { useAppDispatch } from "../../../../shared/hooks/reduxTypes";
import CardTopInfoBlock from "../../../../components/HallCard/CardTopInfoBlock/CardTopInfoBlock";
import { useRef } from "react";
import { useTelegram } from "../../../../shared/hooks/useTelegram";
import { useNavigate } from "react-router-dom";

const FoodsList: FC<BaseMenuListInterface<FoodListItemApiModel[]>> = ({
  isError,
  isLoading,
  data,
}) => {
  const { webApp } = useTelegram();
  const { data: userData } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
  );
  const dispatch = useAppDispatch();
  const [updateCart] = useLazyUpdateCartQuery();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <MenuList>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </MenuList>
    );
  }

  if (data && data.length === 0) {
    return (
      <div
        style={{
          flex: "1 0 auto",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          color: "var(--main-text-color)",
          fontSize: 24,
        }}
      >
        Ничего не найдено
      </div>
    );
  }

  if (userData) {
    return (
      <MenuList>
        {data &&
          data.map((el) => {
            return (
              <AnimatedCard startAnimation={true} key={el.id}>
                <StyledMenuCard
                  image={el.photo}
                  price={el.price}
                  addInfo={` (${el.weight})`}
                  name={el.name}
                  is_busket={
                    userData &&
                    userData?.data &&
                    userData.data.food &&
                    userData?.data?.food[String(el.id)]
                      ? true
                      : false
                  }
                  onClickPrice={() => {
                    if (
                      userData &&
                      userData?.data?.food &&
                      !userData?.data?.food[String(el.id)]
                    ) {
                      const update = userApiSlice.util.updateQueryData(
                        "getUserByTgID",
                        webApp?.initDataUnsafe?.user?.id,
                        (draft) => {
                          const cartData = draft.data;

                          cartData.food[String(el.id)] = {
                            quantity: 1,
                            price: el.price,
                            weight: el.weight,
                            name: el.name,
                            photo: el.photo,
                          };

                          return draft;
                        }
                      );

                      dispatch(update);

                      const data = JSON.parse(JSON.stringify(userData.data));

                      if (data.food) {
                        data.food[String(el.id)] = {
                          quantity: 1,
                          price: el.price,
                          weight: el.weight,
                          name: el.name,
                          photo: el.photo,
                        };
                      }

                      if (userData?.id) {
                        updateCart({ user_id: userData.id, data: data });
                      }
                    } else {
                      navigate("/order");
                    }
                  }}
                />
              </AnimatedCard>
            );
          })}
      </MenuList>
    );
  }

  return <></>;
};

export default FoodsList;
