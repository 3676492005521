import { Skeleton } from "@mui/material";
import { SxProps, Theme } from "@mui/material";
import { useTheme } from "../../../../../shared/hooks/useTheme";
import SkeletonCard from "../../../../../ui/SkeletonCard/SkeletonCard";

const LoadingCardList = () => {
  const { theme } = useTheme();

  return (
    <>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </>
  );
};

export default LoadingCardList;
