import { useNavigate } from "react-router-dom";
import MainHeader from "../../components/header/main/MainHeader";
import HallForm from "../../modules/HallDescription/HallDescription";
import { useTelegram } from "../../shared/hooks/useTelegram";
import useHistory from "../../shared/hooks/useHistory";
import useBackButton from "../../shared/hooks/useBackButton";
import Loading from "../../modules/Loading/Loading";
import { useGetUserByTgIDQuery } from "../../api/userAPI";
import { TG_ID } from "../../shared/constants/AppOptions";
import { useCallback, useEffect } from "react";
import { usePageAnimationClassName } from "../../shared/hooks/usePageAnimationClassName";

const HallDescriptionPage = () => {
  const { webApp } = useTelegram();


  const {
    data: userData,
    isLoading: userLoadingData,

    isError: userErrorData,
  } = useGetUserByTgIDQuery(webApp?.initDataUnsafe?.user?.id);

  if (userLoadingData) return <Loading />;

  if (userErrorData) {
    return <></>;
  }

  return (
    <div
      style={{
        width: "100%",
        flexDirection: "column",
        display: "flex",
        flex: "1 0 auto",
      }}
    >
      <HallForm />
    </div>
  );
};

export default HallDescriptionPage;
