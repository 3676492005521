import { FC, useRef, useEffect, useState } from "react";
import { HallsHeaderProps } from "./interface";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import SettingsSVG from "../../../../shared/icons/SettingSVG";
import { useSpring, animated } from "@react-spring/web";

const HallsHeader: FC<HallsHeaderProps> = ({ onClickSettings }) => {
  const [firstRender, setFirstRender] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const togglerStyleProps = useSpring({
    from: { opacity: 0, scale: 0.5 }, // Start offscreen, faded
    to: { opacity: 1, scale: 1 }, // Animate to normal
    config: {
      duration: 500,
    },
  });

  const settingButtonStyleProps = useSpring({
    from: { opacity: 0, scale: 0.5 }, // Start offscreen, faded
    to: { opacity: 1, scale: 1 }, // Animate to normal
    config: {
      duration: 500,
    },
  });

  const SettingButton = animated(Button);

  return (
    <div className={styles.cont}>
      <animated.span
        style={togglerStyleProps}
        ref={textRef}
        className={styles.text}
      >
        НАШИ ЗАЛЫ
      </animated.span>
      <SettingButton
        style={settingButtonStyleProps}
        ref={buttonRef}
        sx={{ width: 30, height: 30, padding: 0 }}
        onClick={onClickSettings}
        className={styles.settingBtn}
      >
        <SettingsSVG />
      </SettingButton>
    </div>
  );
};

export default HallsHeader;
