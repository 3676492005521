import { FC } from "react";
import { Card, CardMedia } from "@mui/material";
import { HallCardProps } from "../../models/HallsCard";
import HallName from "../../../../components/HallCard/CardName/HallCardName";
import HallInfo from "./Info/HallInfo";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import CardPrice from "../../../../components/CardPrice/CardPrice";
import HallCardComponent from "../../../../components/HallCard/HallCard";

const HallCard: FC<HallCardProps> = ({
  isFree,
  capacity,
  name,
  to,
  price,
  photo,
}) => {
  return (
    <Link className={styles.container} to={to}>
      <HallCardComponent
        mediaChild={
          <>
            <HallInfo capacity={capacity} isFree={isFree} />
            <HallName name={name} />
          </>
        }
        image={photo}
      >
        <CardPrice>
          {
            `${price} руб./час`
          }
        </CardPrice>
      </HallCardComponent>
    </Link>
  );
};

export default HallCard;
