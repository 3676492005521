import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { usePathname } from "../../shared/hooks/usePathname";
import { Button } from "@mui/material";
import useHistory from "../../shared/hooks/useHistory";
import LinkButton from "../LinkButton/LinkButton";
const Navbar = () => {
  const pathname = usePathname();
  const { navigate } = useHistory();

  return (
    <div className={styles.container}>
      <LinkButton
        className={`${pathname === "/" && styles.active} ${styles.link}`}
        to="/"
        variant="outlined"
        animationClassName=""
      >
        ЗАЛЫ
      </LinkButton>

      <LinkButton
        className={`${pathname === "/menu" && styles.active} ${styles.link}`}
        to="/menu"
        variant="outlined"
        animationClassName=""
      >
        МЕНЮ
      </LinkButton>

      <LinkButton
        className={`${pathname === "/order" && styles.active} ${styles.link}`}
        to="/order"
        variant="outlined"
        animationClassName=""
      >
        ЗАКАЗ
      </LinkButton>
    </div>
  );
};

export default Navbar;
