import { FC } from "react";
import { SVGIcons } from "./interface";

const SettingsSVG: FC<SVGIcons> = ({ className, width = 18, height = 18 }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 64 64"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_63" data-name="Layer 63">
        <path d="m60 30.5h-28.07a8.07 8.07 0 0 0 -15.86 0h-12.07a1.5 1.5 0 0 0 0 3h12.07a8.07 8.07 0 0 0 15.86 0h28.07a1.5 1.5 0 0 0 0-3z"></path>
        <path d="m4 12.07h32.16a8.07 8.07 0 0 0 15.86 0h8a1.5 1.5 0 0 0 0-3h-8.02a8.07 8.07 0 0 0 -15.86 0h-32.14a1.5 1.5 0 0 0 0 3z"></path>
        <path d="m60 51.93h-8a8.07 8.07 0 0 0 -15.86 0h-32.14a1.5 1.5 0 0 0 0 3h32.16a8.07 8.07 0 0 0 15.86 0h8a1.5 1.5 0 0 0 0-3z"></path>
      </g>
    </svg>
  );
};

export default SettingsSVG;
