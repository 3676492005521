import { animated, useSpring } from "@react-spring/web";
import { usePageAnimationClassName } from "../../../../shared/hooks/usePageAnimationClassName";
import FormSubmitButton from "../../../../ui/FormSubmitButton/FormSubmitButton";
import { NewUserFormProps } from "./interface";
import styles from "./styles.module.scss";
import { FC } from "react";
import { useRef, useEffect } from "react";
const Form: FC<NewUserFormProps> = ({
  descriptionText,
  headerText,
  children,
  onSubmit,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const descRef = useRef<HTMLDivElement>(null);

  // Определяем начальные значения opacity и scale
  const headerStyle = useSpring({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 250 }, // Настройки анимации
    delay: 100,
  });

  const textStyle = useSpring({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 250 }, // Настройки анимации
    delay: 250,
  });

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <animated.div
        style={headerStyle}
        ref={headerRef}
        className={styles.formHeader}
      >
        {headerText}
      </animated.div>

      <animated.div style={textStyle} ref={descRef} className={styles.formDesc}>
        {descriptionText}
      </animated.div>

      {children}

      <FormSubmitButton
        type="submit"
        className={styles.submitBtn}
        onClick={() => {}}
      >
        ПРОДОЛЖИТЬ
      </FormSubmitButton>
    </form>
  );
};

export default Form;
