import { BaseComponentInterface } from "../../../shared/models/BaseComponentInterface";
import { FC } from "react";
import styles from "./styles.module.scss";
const CardTopInfoBlock: FC<BaseComponentInterface> = ({
  className,
  children,
}) => {
  return <span className={`${className} ${styles.container}`}>{children}</span>;
};

export default CardTopInfoBlock;
