import { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useHistory = () => {
  const location = useLocation();
  const [history, setHistory] = useState<string[]>([]);
  const nav = useNavigate();

  const loc = useLocation();

  const navigate = useCallback((location: string) => {
    nav(location);
    setHistory((prevHistory) => [...prevHistory, location]);
  }, []);

  const goBack = useCallback(() => {
    nav(-1);
    if (history.length > 1) {
      const newHistory = history.slice(0, -1);
      setHistory(newHistory);
    } else {
      location.state = {};
    }
  }, [history]);
  return { navigate, goBack, history };
};

export default useHistory;
