import MenuCard from "../../ui/MenuCard/MenuCard";
import contImage from "../../shared/assets/BG.webp";
import MenuList from "./components/MenuList/MenuList";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import SettingsSVG from "../../shared/icons/SettingSVG";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import FilterForm from "./components/FilterForm/FilterForm";
import MainHeader from "../../components/header/main/MainHeader";
import TogglerSection from "./components/TogglerSection/TogglerSection";
import SwiperMenu from "./components/SwiperMenu/SwiperMenu";
const Menu = () => {
  const [swiperValue, setSwiperValue] = useState(0);
  const [showSwiper, setShowSwiper] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const swiperRef = useRef<SwiperRef>(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      setShowSwiper(true);
    });
  }, []);

  const onClickToggleButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    value: number
  ) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(value);
    }
  };

  const onClickModalFilterBtn = () => {
    setOpenFilters(true);
  };
  return (
    <>
      <div className={styles.container}>
        <TogglerSection
          onClickModalFilterBtn={onClickModalFilterBtn}
          activeToggleValue={swiperValue}
          onClickToggleButton={onClickToggleButton}
        />
      </div>

      {showSwiper && (
        <SwiperMenu
          swiperValue={swiperValue}
          swiperRef={swiperRef}
          onSlideChange={(swiper: any) => {
            setSwiperValue(swiper.activeIndex);
          }}
        />
      )}

      <FilterForm
        open={openFilters}
        onOpen={() => setOpenFilters(true)}
        onClose={() => setOpenFilters(false)}
      />
    </>
  );
};

export default Menu;
