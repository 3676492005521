export function calculateDayDiscount(date: Date) {
  const dayOfWeek = date.getDay(); // 0 - воскресенье, 6 - суббота
  const hours = date.getHours();

  // 10% скидка: понедельник - воскресенье, 10:00 - 17:00
  if (hours >= 10 && hours < 18) {
    return 0.1; // 10%
  }

  // 5% скидка: понедельник - четверг, 18:00 - 09:00 пятницы
  if (
    (dayOfWeek >= 1 && dayOfWeek <= 4 && (hours >= 18 || hours < 9)) ||
    (dayOfWeek === 5 && hours < 9)
  ) {
    return 0.05; // 5%
  }

  // Без скидки: пятница - воскресенье, 18:00 - 10:00 понедельника
  if (
    (dayOfWeek === 5 && hours >= 18) ||
    dayOfWeek === 6 ||
    dayOfWeek === 0 ||
    (dayOfWeek === 1 && hours < 10)
  ) {
    return 0; // 0%
  }

  // Если ни одно из условий не выполняется, вернуть 0%
  return 0;
}
