import { createTheme } from "@mui/material/styles";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { transform } from "typescript";

const theme = createTheme({
  components: {
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: 22,
          fontWeight: 400,
          margin: "0",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          width: "100%",
        },
      },
      defaultProps: {
        iconMapping: {
          success: (
            <CheckRoundedIcon
              sx={{ color: "white", margin: 0, fontSize: 40 }}
            />
          ),
          error: <CloseRoundedIcon sx={{ color: "white", margin: 0 }} />,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "transparent",
        },
        backdrop: {
          backgroundColor: "rgba(0, 0, 0, 0.45)",
          backdropFilter: "blur(2px)",
          transition: "1s linear",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--main-background-color)",
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
          padding: "12px",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "var(--slider-theme-color)", // Основной цвет слайдера
        },
        thumb: {
          height: 24, // Высота "ползунка"
          width: 24, // Ширина "ползунка"
          backgroundColor: "var(--main-background-color)", // Цвет "ползунка"
          border: "2px solid currentColor",
          "&:hover, &.Mui-focusVisible": {
            boxShadow: "inherit",
          },
        },
        track: {
          height: 8, // Высота дорожки
          borderRadius: 4, // Скругление углов дорожки
        },
        rail: {
          height: 8, // Высота рельса
          borderRadius: 4, // Скругление углов рельса
          backgroundColor: "var(--slider-rail-color)", // Цвет рельса
        },
        valueLabel: {
          bgcolor: "#3f51b5", // Цвет фона для всплывающей подсказки
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          transform: "translateZ(0, 0, 0)",
          maxWidth: undefined,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: 30,
          height: 30,
          padding: 0,
          borderRadius: "50%",
          background: "var(--extra-theme-color)",
          color: "var(--main-theme-color)", // Цвет неактивного состояния
          cursor: "pointer",
          flex: "0 0 auto",
          ":hover": {
            background: "var(--extra-theme-color)",
          },
          "&.Mui-checked": {
            background: "var(--extra-theme-color)",
            color: "var(--main-theme-color)", // Цвет активного состояния
          },
          "&.Mui-disabled": {
            color: "#d3d3d3", // Цвет для отключенного состояния
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "var(--text-color-label)", // Стандартный цвет текста
          marginLeft: 8,
          fontWeight: 400,
          lineHeight: "19px",
          fontSize: 16,
          textAlign: "left",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: "scale(1)",
          background: "var(--skeleton-bg)",
        },
      },
    },
  },
});

export default theme;
