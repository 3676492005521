import { FC, useEffect, useState } from "react";
import SliderComponent from "../../../../ui/Slider/Slider";
import styles from "./styles.module.scss";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import FormSubmitButton from "../../../../ui/FormSubmitButton/FormSubmitButton";
import SwipeableDrawerComponent from "../../../../components/SwipebleDrawer/SwipeableDrawer";
import { AutorenewRounded } from "@mui/icons-material";
import { FilterFormProps } from "./interface";
import TimeInput from "../../../../components/Inputs/TimeInput/TimeInput";
import SelectComponent from "../../../../ui/Select/Select";
import { SORTOPTIONS } from "../../../../shared/constants/SelectOptions";
import { useDebouncedCallback } from "use-debounce";
import React from "react";
import ColumnForm from "../ColumnForm/ColumnForm";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../shared/hooks/reduxTypes";
import {
  setPriceValue,
  setCapacityValue,
  clearForm,
  setStartDate,
  setReqValues,
} from "../../slices/FilterSlice";
import { useGetDateTimeQuery } from "../../../../api/userAPI";
import { setDate } from "../../slices/FilterSlice";
import {
  useGetFilteredHallsMutation,
  useLazyGetHallsQuery,
} from "../../../../api/hallsAPI";
import SlideCapacity from "./SlideCapacity/SlideCapacity";
import SlidePrice from "./SlidePrice/SlidePrice";
import moment from "moment";

const FilterForm: FC<FilterFormProps> = ({ open, onClose, onOpen }) => {
  const {
    data: nowDate,
    isLoading: isLoadingDate,
    isError: isErrorDate,
  } = useGetDateTimeQuery(undefined);

  const dateValue = useAppSelector((state) => state.hallsFilterForm.date);
  const sort = useAppSelector((state) => state.hallsFilterForm.sortValue);
  const hour = useAppSelector((state) => state.hallsFilterForm.hour);
  const minute = useAppSelector((state) => state.hallsFilterForm.minute);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (nowDate?.datetime) {
      dispatch(setStartDate(nowDate.datetime));
    }
  }, [isLoadingDate]);
  useEffect(() => {
    if (isErrorDate) dispatch(setDate(new Date().toISOString().slice(0, 10)));
  }, [isErrorDate]);

  return (
    <>
      <SwipeableDrawerComponent
        onClose={onClose}
        onOpen={onOpen}
        header="ФИЛЬТРЫ"
        leftHeaderButton={<AutorenewRounded />}
        leftHeaderButtonClick={() => {
          dispatch(clearForm());
        }}
        open={open}
      >
        <div className={styles.form}>
          <SlideCapacity />
          <SlidePrice />
          <div className={styles.label}>Дата посещения:</div>

          <CustomDatePicker
            dateValue={dateValue ? moment(dateValue).toDate() : null}
            onChangeDate={(dateVal) => {
              if (moment(dateVal).format("YYYY-MM-DD") === dateValue) {
                dispatch(setDate(null));
              } else {
                dispatch(setDate(moment(dateVal).format("YYYY-MM-DD")));
              }
            }}
            className={styles.datepicker}
          />

          <ColumnForm />

          <FormSubmitButton
            className={styles.submitBtn}
            onClick={(e) => {
              dispatch(setReqValues());
              onClose(e);
            }}
            type="button"
          >
            Применить
          </FormSubmitButton>
        </div>
      </SwipeableDrawerComponent>
    </>
  );
};

export default React.memo(FilterForm);
