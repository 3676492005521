import { useEffect } from "react";
import NewUserForm from "../../modules/NewUserForm/NewUserForm";
import { usePageAnimationClassName } from "../../shared/hooks/usePageAnimationClassName";
import Loading from "../../modules/Loading/Loading";

const NewUserFormPage = () => {
  const { updateAnimationClassName } = usePageAnimationClassName();

  return <NewUserForm />;
};

export default NewUserFormPage;
