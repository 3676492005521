import { FC } from "react";
import {
  HallCard,
  HallNameProps,
} from "../../../modules/Halls/models/HallsCard";
import styles from "./styles.module.scss";
const HallCardName: FC<HallNameProps> = ({ name }) => {
  return <div className={styles.name}>{name}</div>;
};

export default HallCardName;
