import Form from "../form/Form";
import { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { FormStepInterface } from "../../models/FormStepInterface";
import { FC } from "react";
import { useSendPhoneNumberDataMutation } from "../../../../api/activateAPI";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../shared/hooks/reduxTypes";
import { createPortal } from "react-dom";
import { AlertTitle, Slide } from "@mui/material";
import { AlertComponent } from "../../../../ui/Alert/Alert";
import { ErrorMessage } from "../../models/FormStepInterface";
import {
  useGetUserByTgIDQuery,
  useLazyUpdateCartQuery,
  userApiSlice,
} from "../../../../api/userAPI";
import { TG_ID } from "../../../../shared/constants/AppOptions";
import { useTelegram } from "../../../../shared/hooks/useTelegram";
const SMSForm: FC<FormStepInterface> = ({ onSubmit }) => {
  const { webApp } = useTelegram();
  const dispatch = useAppDispatch();
  const code = useAppSelector((state) => state.activateInfo.code);
  const phoneNum = useAppSelector((state) => state.activateInfo.phone);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Создаем реф для таймера
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
    status: false,
    message: "",
  });
  const { data: userData } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
  );

  const [updateInfo] = useLazyUpdateCartQuery();

  useEffect(() => {
    if (errorMessage.status === true) {
      // Устанавливаем новый таймер
      timerRef.current = setTimeout(() => {
        setErrorMessage((prev) => ({ ...prev, status: false }));
      }, 2000);
    }

    // Очищаем таймер при размонтировании или изменении errorMessage
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null; // Обнуляем реф
      }
    };
  }, [errorMessage]);

  const submitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (inputRef.current) {
      const codeInput = inputRef.current.value;

      if (code === codeInput && userData?.id) {
        updateInfo({ phone_number: phoneNum, user_id: userData?.id }).then(
          (res) => {
            if (res.data) {
              const update = userApiSlice.util.updateQueryData(
                "getUserByTgID",
                webApp?.initDataUnsafe?.user?.id,
                (draft) => {
                  draft.phone_number = phoneNum;

                  return draft;
                }
              );

              dispatch(update);
            }

            onSubmit();
          }
        );
      } else {
        setErrorMessage({ status: true, message: "Неправильный код" });
      }
    }
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({
        behavior: "smooth", // Плавная прокрутка
        block: "center", // Центрирует элемент по вертикали
        inline: "nearest", // Параметры прокрутки по горизонтали
      });
    }
  };

  return (
    <>
      <Form
        headerText="ПОДТВЕРЖДЕНИЕ"
        descriptionText="Введите код подтверждения из SMS"
        onSubmit={submitForm}
      >
        <label>
          <input
            maxLength={6}
            onFocus={handleFocus}
            onBlur={() => {
              window.scrollTo(0, 0);
            }}
            ref={inputRef}
            type="tel"
          />
        </label>
      </Form>
      {createPortal(
        <Slide
          style={{
            position: "fixed",
            top: 30,
            left: 20,
            right: 20,
            width: "auto",
            zIndex: 16,
          }}
          timeout={300}
          direction="down"
          in={errorMessage.status}
          mountOnEnter
          unmountOnExit
        >
          <AlertComponent severity="error">
            <>
              <AlertTitle>{errorMessage.message}</AlertTitle>
            </>
          </AlertComponent>
        </Slide>,
        document.body
      )}
    </>
  );
};

export default SMSForm;
