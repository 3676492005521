import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import { FC } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { BaseComponentInterface } from "../../shared/models/BaseComponentInterface";

interface CheckboxComponentProps extends CheckboxProps {
  label?: React.ReactNode;
}

const CheckboxComponent: FC<CheckboxComponentProps> = ({
  checked,
  onChange,
  name,
  label,
  className,
  onClick,
}) => {
  return (
    <FormControlLabel
      className={className}
      control={
        <Checkbox
          onChange={onChange}
          icon={<></>}
          checked={checked}
          checkedIcon={<CheckRoundedIcon fill={"var(--text-color-label)"} />}
          name={name}
          onClick={onClick}
        />
      }
      label={label}
    />
  );
};

export default CheckboxComponent;
