import styles from "./styles.module.scss";
import CardTopInfoBlock from "../../../../../../components/HallCard/CardTopInfoBlock/CardTopInfoBlock";
import { FC } from "react";
import { HistoryCardProps } from "../interface";
import CardTopMediaContainer from "../../../../../../components/HallCard/CardTopMediaContainer/CardTopMediaContainer";
const HistoryCardInfo: FC<Omit<HistoryCardProps, "name">> = ({
  capacity,
  date,
}) => {
  return (
    <CardTopMediaContainer>
      <CardTopInfoBlock>{capacity} чел.</CardTopInfoBlock>
      <CardTopInfoBlock>{date}</CardTopInfoBlock>
    </CardTopMediaContainer>
  );
};

export default HistoryCardInfo;
