import { FC } from "react";
import { AlertComponentProps } from "./interface";
import { Alert, AlertTitle } from "@mui/material";
import CheckMarkSVG from "../../shared/icons/CheckMarkSVG";
import styles from "./styles.module.scss";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { styled } from "@mui/material/styles";
import { createPortal } from "react-dom";

export const AlertComponent = styled(Alert)(
  ({ theme, severity = "success", icon, className }) => ({
    // Стили для всех Alert
    backgroundColor:
      severity === "success"
        ? "rgba(72, 173, 25, 0.8)"
        : "rgba(255, 77, 77, 0.8)",
    fontSize: 22,
    lineHeight: 26,
    textAlign: "left",
    display: "flex",
    minHeight: 60,
    alignItems: "center",
    borderRadius: 15,
    color: "white",
    backdropFilter: "blur(2px)",
  })
);
