import { Skeleton, SkeletonProps } from "@mui/material";
import { FC } from "react";
import { useTheme } from "../../shared/hooks/useTheme";
import { SxProps, Theme } from "@mui/material";
const SkeletonCard: FC<SkeletonProps> = ({ className, sx, style }) => {
  const { theme } = useTheme();

  const styles: SxProps<Theme> = {
    aspectRatio: "440/400",
    transform: "none",
    borderRadius: 5,
  };

  return <Skeleton style={style} className={className} sx={styles} />;
};

export default SkeletonCard;
