import Select, {
  components,
  GroupBase,
  SelectComponentsConfig,
  OptionProps,
  MenuListProps,
  MenuProps,
} from "react-select";
import { DropDownIndicatorProps, SelectComponentProps } from "./interface";
import { FC, useState } from "react";
import styles from "./styles.module.scss";
import "./select.scss"; // Импорт CSS
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const Option: React.ComponentType<OptionProps<any, false, GroupBase<any>>> = (
  props
) => {
  return <components.Option {...props} />;
};

const DropdownIndicator: FC<DropDownIndicatorProps> = (props) => {
  return (
    <ExpandMoreRoundedIcon
      sx={{
        fontSize: 20,
        color: "var(--main-theme-color)",
        transition: "0.2s linear",
        transform: `rotate(${props.open ? 180 : 0}deg)`,
      }}
    />
  );
};

const Menu: FC<MenuProps<any, false, GroupBase<any>>> = (props) => {
  return <components.Menu className={styles.menu} {...props} />;
};

const MenuList: React.ComponentType<
  MenuListProps<any, false, GroupBase<any>>
> = (props) => {
  return <components.MenuList {...props} />;
};

const MenuPortal = (props: any) => {
  return <components.MenuPortal {...props} />;
};
const SelectComponent: FC<SelectComponentProps> = ({
  options,
  placeholder,
  onChange,
  value,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [uniqueId] = useState(
    () => "select_" + Math.random().toFixed(5).slice(2)
  );
  return (
    <Select
      id={uniqueId}
      isSearchable={false}
      value={value}
      onChange={onChange}
      options={options}
      styles={{
        option: (base) => ({
          ...base,
          height: "100%",
          backgroundColor: "transparent",
        }),
        menu: (base) => ({
          ...base,
          padding: 0,
          background: "transparent",
          left: 0,
          marginTop: 4,
          borderRadius: 15,
          zIndex: 2,
        }),
        menuList: (base) => ({
          ...base,
          background: "var(--input-bg)",
          padding: 0,
          borderRadius: 15,
          overflow: "hidden",
        }),
        menuPortal: (base) => ({
          ...base,
          padding: 0,
          borderRadius: 15,
          background: "var(--input-bg)",
        }),
      }}
      placeholder={placeholder}
      classNamePrefix={"select"}
      className={`select ${styles.select}`}
      components={{
        DropdownIndicator: () => <DropdownIndicator open={isMenuOpen} />,
        Option,
        MenuList,
        MenuPortal,
        Menu,
      }}
      onMenuClose={() => {
        setIsMenuOpen(false);
      }}
      onMenuOpen={() => {
        setIsMenuOpen(true);
      }}
    />
  );
};

export default SelectComponent;
