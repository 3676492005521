import { FC } from "react";
import styles from "./styles.module.scss";
import { HallDescriptionProps } from "./interface";
import { useSpring, animated } from "@react-spring/web";
const HallDescription: FC<HallDescriptionProps> = ({
  children,
  className,
  text,
}) => {
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 500 }, // Настройки анимации
  }));
  return <animated.div style={{opacity, scale}} className={styles.container}>{text}</animated.div>;
};

export default HallDescription;
