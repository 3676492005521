import { FC } from "react";
import { SVGIcons } from "./interface";

const PhoneSVG: FC<SVGIcons> = ({ width = 22, height = 23, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0834 16.215V19.09C20.0845 19.3569 20.0298 19.6211 19.9229 19.8656C19.8159 20.1102 19.6591 20.3297 19.4624 20.5101C19.2658 20.6906 19.0336 20.8279 18.7808 20.9134C18.5279 20.9989 18.26 21.0307 17.9942 21.0067C15.0453 20.6862 12.2126 19.6786 9.72379 18.0646C7.40829 16.5932 5.44515 14.6301 3.97379 12.3146C2.35418 9.81449 1.34627 6.96804 1.0317 4.00583C1.00776 3.74082 1.03925 3.47373 1.12418 3.22156C1.20912 2.96938 1.34563 2.73766 1.52502 2.54114C1.70442 2.34461 1.92277 2.1876 2.16617 2.08008C2.40957 1.97257 2.6727 1.91692 2.93879 1.91667H5.81379C6.27887 1.91209 6.72975 2.07678 7.08239 2.38005C7.43503 2.68332 7.66536 3.10447 7.73045 3.565C7.8518 4.48506 8.07684 5.38845 8.40129 6.25792C8.53023 6.60093 8.55813 6.97371 8.4817 7.33209C8.40527 7.69048 8.2277 8.01944 7.97004 8.28L6.75295 9.49708C8.1172 11.8963 10.1037 13.8828 12.503 15.2471L13.72 14.03C13.9806 13.7723 14.3096 13.5948 14.6679 13.5183C15.0263 13.4419 15.3991 13.4698 15.7421 13.5987C16.6116 13.9232 17.515 14.1482 18.435 14.2696C18.9006 14.3353 19.3257 14.5697 19.6296 14.9284C19.9335 15.2871 20.095 15.745 20.0834 16.215Z"
        stroke="#F6DA87"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PhoneSVG;
