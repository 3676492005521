import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  price: 0,
  discount: 0,
};

const OrderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {
    setPrice: (state, action: PayloadAction<number>) => {
      state.price = action.payload;
    },
    setDiscount: (state, action: PayloadAction<number>) => {
      state.discount = action.payload;
    },
  },
});

const { setPrice, setDiscount } = OrderSlice.actions;

export default OrderSlice.reducer;
