import { useNavigate } from "react-router-dom";
import {
  useGetUserByTgIDQuery,
  useLazyGetUserByTgIDQuery,
  userApiSlice,
} from "../../api/userAPI";
import StartBackgroundContainer from "../../components/StartBackground/StartBackground";
import LogoSVG from "../../shared/icons/LogoSVG";
import styles from "./styles.module.scss";
import { useTelegram } from "../../shared/hooks/useTelegram";
import { TG_ID } from "../../shared/constants/AppOptions";
import { useEffect, useLayoutEffect } from "react";
import { useAppDispatch } from "../../shared/hooks/reduxTypes";
import { setViewGift } from "../../shared/slices/globalSlice";
const Loading = () => {
  const { webApp } = useTelegram();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();



  return (
    <StartBackgroundContainer className={styles.container}>
      <LogoSVG className={styles.logo} />

      <div className={styles.loading}>Загрузка...</div>
    </StartBackgroundContainer>
  );
};

export default Loading;
