import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import styles from "./styles.module.scss";
import { TimePicker } from "./TimePicker";
import { forwardRef, useEffect } from "react";

interface DateTimePickerProps {
  dateValue: Date | null;
  time: { hours: number; minutes: number } | null;
  onChangeDate: (value: Date) => void;
  onChangeTime: (val: { hours: number; minutes: number }) => void;
  onClickWindow?: () => void;
}

const DateTimePicker = forwardRef<HTMLDivElement, DateTimePickerProps>(
  ({ dateValue, time, onChangeDate, onChangeTime, onClickWindow }, ref) => {
    useEffect(() => {
      if (onClickWindow) window.addEventListener("click", onClickWindow);

      return () => {
        if (onClickWindow) window.removeEventListener("click", onClickWindow);
      };
    }, []);
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={styles.container}
        ref={ref}
      >
        <CustomDatePicker
          loader={
            <>
              <div style={{ width: "100%", height: 214 }}></div>
            </>
          }
          classNameWeekDays={styles.dateWeeks}
          classNameDatesContainer={styles.datesContainer}
          className={styles.datePicker}
          dateValue={dateValue}
          classNameDate={styles.dateItem}
          onChangeDate={onChangeDate}
        />
        <TimePicker onClickTime={onChangeTime} time={time} />
      </div>
    );
  }
);

export default DateTimePicker;
