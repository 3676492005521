import SliderComponent from "../../../../../ui/Slider/Slider";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../shared/hooks/reduxTypes";
import { setPriceValue } from "../../../slices/FilterSlice";
import React from "react";
const SlidePrice = () => {
  const price = useAppSelector((state) => state.hallsFilterForm.priceValue);

  const dispatch = useAppDispatch();
  return (
    <SliderComponent
      name="Цена за час: "
      valueChild={price === 0 ? "Не имеет значения" : `До ${price} руб.`}
      onChange={(e, value) => {
        if (typeof value === "number") {
          dispatch(setPriceValue(value));
        }
      }}
      min={0}
      max={15000}
      value={price}
    />
  );
};

export default React.memo(SlidePrice);
