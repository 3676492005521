import { FC } from "react";
import styles from "./styles.module.scss";
import { CardPriceProps } from "./interface";
import { useTheme } from "../../shared/hooks/useTheme";
import { Button } from "@mui/material";

const CardPrice: FC<CardPriceProps> = ({ children, className, onClick }) => {
  const theme = useTheme();

  return (
    <Button
      type={"button"}
      onClick={onClick}
      data-theme={theme.theme}
      className={`${styles.price} ${className}`}
    >
      <span>{children}</span>
    </Button>
  );
};

export default CardPrice;
