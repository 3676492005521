import { Button } from "@mui/material";
import styles from "./styles.module.scss";

const TIMERANGES: { hours: number; minutes: number }[] = [];

for (let hours = 0; hours < 24; hours++) {
  for (let minutes = 0; minutes < 60; minutes += 30) {
    TIMERANGES.push({ hours, minutes });
  }
}

interface TimePickerProps {
  time: { hours: number; minutes: number } | null;
  onClickTime: (val: { hours: number; minutes: number }) => void;
}

export const TimePicker = ({ time, onClickTime }: TimePickerProps) => {
  const formattedTime = (el: { hours: number; minutes: number }) => {
    return `${el.hours.toString().padStart(2, "0")}:${el.minutes
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className={styles.select}>
      <div className={styles.content}>
        {TIMERANGES.map((el, index) => {
          return (
            <Button
              sx={{
                minHeight: undefined,
                color: undefined,
                borderRadius: 0,
                minWidth: 0,
              }}
              className={`${styles.checkboxBtn} ${
                el.hours === time?.hours &&
                el.minutes === time?.minutes &&
                styles.active
              }`}
              onClick={(e) => {
                e.stopPropagation();
                onClickTime(el);
              }}
              key={index}
            >
              {formattedTime(el)}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
