import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  view_gift: false,
  isAuth: false,
};

const globalAppSLice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    setViewGift: (state, action: PayloadAction<boolean>) => {
      state.view_gift = action.payload;
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
  },
});

export const { setViewGift, setIsAuth } = globalAppSLice.actions;
export default globalAppSLice.reducer;
