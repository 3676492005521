import { useCallback, useEffect } from "react";
import { useGetUserByTgIDQuery } from "../../api/userAPI";
import HeaderProfile from "../../components/header/profile/HeaderProfile";
import Loading from "../../modules/Loading/Loading";
import Profile from "../../modules/Profile/Profile";
import { TG_ID } from "../../shared/constants/AppOptions";
import useBackButton from "../../shared/hooks/useBackButton";
import useHistory from "../../shared/hooks/useHistory";
import { usePageAnimationClassName } from "../../shared/hooks/usePageAnimationClassName";
import { useTelegram } from "../../shared/hooks/useTelegram";
import { useTheme } from "../../shared/hooks/useTheme";

const ProfilePage = () => {
  const { webApp } = useTelegram();

  const {
    data: userData,
    isLoading: userLoadingData,
    isError: userErrorData,
  } = useGetUserByTgIDQuery(webApp?.initDataUnsafe?.user?.id);

  if (userLoadingData) return <Loading />;

  if (userErrorData) {
    return <></>;
  }

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <HeaderProfile />
      <Profile />
    </div>
  );
};

export default ProfilePage;
