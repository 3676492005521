import { FC } from "react";
import { SVGIcons } from "./interface";

const HumanSVG: FC<SVGIcons> = ({ className, width = 21, height = 20 }) => {
  return (
    <svg
      width={21}
      height={20}
      className={className}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3405 10C8.98658 10 7.82755 9.51042 6.8634 8.53125C5.89925 7.55208 5.41718 6.375 5.41718 5C5.41718 3.625 5.89925 2.44792 6.8634 1.46875C7.82755 0.489583 8.98658 0 10.3405 0C11.6944 0 12.8534 0.489583 13.8176 1.46875C14.7817 2.44792 15.2638 3.625 15.2638 5C15.2638 6.375 14.7817 7.55208 13.8176 8.53125C12.8534 9.51042 11.6944 10 10.3405 10ZM0.493866 20V16.5C0.493866 15.7917 0.673362 15.1406 1.03235 14.5469C1.39134 13.9531 1.86829 13.5 2.46319 13.1875C3.73505 12.5417 5.02742 12.0573 6.3403 11.7344C7.65318 11.4115 8.98658 11.25 10.3405 11.25C11.6944 11.25 13.0278 11.4115 14.3407 11.7344C15.6536 12.0573 16.9459 12.5417 18.2178 13.1875C18.8127 13.5 19.2896 13.9531 19.6486 14.5469C20.0076 15.1406 20.1871 15.7917 20.1871 16.5V20H0.493866ZM2.95552 17.5H17.7255V16.5C17.7255 16.2708 17.669 16.0625 17.5562 15.875C17.4434 15.6875 17.2947 15.5417 17.11 15.4375C16.0023 14.875 14.8843 14.4531 13.756 14.1719C12.6278 13.8906 11.4893 13.75 10.3405 13.75C9.19172 13.75 8.0532 13.8906 6.92494 14.1719C5.79668 14.4531 4.67868 14.875 3.57094 15.4375C3.38631 15.5417 3.23759 15.6875 3.12476 15.875C3.01194 16.0625 2.95552 16.2708 2.95552 16.5V17.5ZM10.3405 7.5C11.0174 7.5 11.597 7.25521 12.079 6.76562C12.5611 6.27604 12.8021 5.6875 12.8021 5C12.8021 4.3125 12.5611 3.72396 12.079 3.23438C11.597 2.74479 11.0174 2.5 10.3405 2.5C9.66354 2.5 9.08402 2.74479 8.60195 3.23438C8.11987 3.72396 7.87883 4.3125 7.87883 5C7.87883 5.6875 8.11987 6.27604 8.60195 6.76562C9.08402 7.25521 9.66354 7.5 10.3405 7.5Z"
        fill="#F6DA87"
      />
    </svg>
  );
};

export default HumanSVG;
