import { Skeleton } from "@mui/material";
import SkeletonCard from "../../../../ui/SkeletonCard/SkeletonCard";
import styles from "../../styles.module.scss";

const LoadingScreen = () => {
  return (
    <div className={styles.container}>
      <Skeleton sx={{ marginTop: "30px", height: "30px" }} />

      <SkeletonCard style={{ marginTop: 20 }} />

      <Skeleton
        sx={{ marginTop: "30px", height: "60px", borderRadius: "15px" }}
      />

      <Skeleton
        sx={{ marginTop: "10px", height: "60px", borderRadius: "15px" }}
      />

      <Skeleton
        sx={{ marginTop: "10px", height: "60px", borderRadius: "15px" }}
      />

      <Skeleton
        sx={{ marginTop: "10px", height: "60px", borderRadius: "15px" }}
      />

      <Skeleton
        sx={{ marginTop: "10px", height: "60px", borderRadius: "15px" }}
      />
    </div>
  );
};

export default LoadingScreen;
