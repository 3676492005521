import { Card, CardContent, CardMedia, styled } from "@mui/material";
import { FC } from "react";
import { MenuCardProps } from "./interface";
import CardPrice from "../../components/CardPrice/CardPrice";
import styles from "./styles.module.scss";
import { useTheme } from "../../shared/hooks/useTheme";
import { transformPathToPhoto } from "../../shared/functions/transformPathToPhoto";

const MenuCard: FC<MenuCardProps> = ({
  className,
  image,
  is_busket = false,
  price,
  name,
  addInfo,
  onClickPrice,
}) => {
  const { theme } = useTheme();
  return (
    <Card data-theme={theme} className={`${styles.card} ${className}`}>
      <>
        <CardMedia
          className={styles.media}
          image={transformPathToPhoto(image)}
        />
        <CardContent className={styles.content}>
          <div>
            {name} <span>{addInfo}</span>
          </div>
        </CardContent>

        {price && (
          <CardPrice
            className={is_busket ? `${styles.basket}` : ``}
            onClick={onClickPrice}
          >
            {is_busket ? "Корзина" : `${price}р`}
          </CardPrice>
        )}
      </>
    </Card>
  );
};

const StyledMenuCard = styled(MenuCard)(({ theme }) => ({}));

export default StyledMenuCard;
