import { Button, SwipeableDrawer, SwipeableDrawerProps } from "@mui/material";
import styles from "./styles.module.scss";
import { FC, useEffect, useState } from "react";
import { SwiperableDrawerComponentProps } from "./interface";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const SwipeableDrawerComponent: FC<SwiperableDrawerComponentProps> = ({
  open,
  onClose,
  onOpen,
  header,
  classNameHeader,
  children,
  leftHeaderButton,
  leftHeaderButtonClick,
  onTransitionEnter,
  onTransitionExited,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [exist, setExist] = useState(false);

  useEffect(() => {
    if (open) {
      setExist(true);
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setOpenDrawer(true);
        });
      });
    } else {
      setOpenDrawer(false);
    }
  }, [open]);

  return (
    <>
      {exist && (
        <SwipeableDrawer
          onTransitionEnter={onTransitionEnter}
          onTransitionExited={() => {
            if (onTransitionExited) {
              onTransitionExited();
            }
            requestAnimationFrame(() => {
              setExist(false);
            });
          }}
          open={openDrawer}
          onClose={onClose}
          onOpen={onOpen}
          anchor="bottom"
        >
          <div className={styles.menu}>
            <Button
              sx={{ margin: 0 }}
              onClick={(e) => {
                onClose(e);
              }}
              className={styles.closeBtn}
            >
              <CloseRoundedIcon />
            </Button>

            {leftHeaderButton && (
              <Button
                sx={{ margin: 0 }}
                onClick={leftHeaderButtonClick}
                className={styles.leftHeaderButton}
              >
                {leftHeaderButton}
              </Button>
            )}

            {header && (
              <div className={`${styles.header} ${classNameHeader}`}>
                {header}
              </div>
            )}

            {children}
          </div>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default SwipeableDrawerComponent;
