import SliderComponent from "../../../../../ui/Slider/Slider";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../shared/hooks/reduxTypes";
import { setCapacityValue } from "../../../slices/FilterSlice";
import React from "react";
const SlideCapacity = () => {
  const capacity = useAppSelector(
    (state) => state.hallsFilterForm.capacityValue
  );

  const dispatch = useAppDispatch();
  return (
    <SliderComponent
      name="Вместимость: "
      valueChild={
        capacity === 0 ? "Не имеет значения" : `До ${capacity} человек`
      }
      onChange={(e, value) => {
        if (typeof value === "number") {
          dispatch(setCapacityValue(value));
        }
      }}
      min={0}
      max={100}
      value={capacity}
    />
  );
};

export default React.memo(SlideCapacity);
