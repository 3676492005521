import { FC } from "react";
import { SVGIcons } from "./interface";

const EditSVG: FC<SVGIcons> = ({ width = 19, height = 19, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.70837 3.16667H3.16671C2.74678 3.16667 2.34405 3.33348 2.04712 3.63042C1.75019 3.92735 1.58337 4.33008 1.58337 4.75V15.8333C1.58337 16.2533 1.75019 16.656 2.04712 16.9529C2.34405 17.2499 2.74678 17.4167 3.16671 17.4167H14.25C14.67 17.4167 15.0727 17.2499 15.3696 16.9529C15.6666 16.656 15.8334 16.2533 15.8334 15.8333V10.2917M14.6459 1.97917C14.9608 1.66422 15.388 1.48729 15.8334 1.48729C16.2788 1.48729 16.7059 1.66422 17.0209 1.97917C17.3358 2.29411 17.5128 2.72127 17.5128 3.16667C17.5128 3.61207 17.3358 4.03922 17.0209 4.35417L9.50004 11.875L6.33337 12.6667L7.12504 9.5L14.6459 1.97917Z"
        stroke="#6F3C14"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EditSVG;
