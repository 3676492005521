import { Button } from "@mui/material";
import { useTheme } from "../../../../../shared/hooks/useTheme";
import Count from "./Count/Count";
import { OrderCardProps } from "./interface";
import styles from "./styles.module.scss";
import { FC } from "react";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";

import { useRef } from "react";
import { useTelegram } from "../../../../../shared/hooks/useTelegram";

import { transformPathToPhoto } from "../../../../../shared/functions/transformPathToPhoto";
const OrderCard: FC<OrderCardProps> = ({
  image,
  count,
  name,
  className,
  price,
  plusClick,
  minusClick,
  onClickDelete,
}) => {
  const { theme } = useTheme();
  const deleteButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={styles.container}>
      <div data-theme={theme} className={`${styles.card} ${className}`}>
        <img
          className={styles.image}
          src={transformPathToPhoto(image)}
          alt={`${name}`}
        />
        <div className={styles.info}>
          <span className={styles.name}>{name}</span>
          <span className={styles.price}>{price}р</span>
        </div>

        {count !== undefined && (
          <Count count={count} plusClick={plusClick} minusClick={minusClick} />
        )}
      </div>

      <Button
        onTransitionEnd={() => {
          if (deleteButtonRef.current) {
            deleteButtonRef.current.style.transition = "";
          }
        }}
        onClick={(e) => {
          if (onClickDelete) onClickDelete(e);
        }}
        style={{ padding: 0 }}
        ref={deleteButtonRef}
        className={`${styles.deleteBtn}`}
      >
        <DeleteOutlineTwoToneIcon />
      </Button>
    </div>
  );
};

export default OrderCard;
