import { useCallback, useEffect } from "react";
import { useTelegram } from "./useTelegram";
import { HapticFeedback } from "../types/types";

const useVibration = () => {
  const { webApp } = useTelegram();

  const impactOccurred = useCallback(
    (
      style: "light" | "medium" | "heavy" | "rigid" | "soft"
    ): HapticFeedback | undefined => {
      return webApp?.HapticFeedback.impactOccurred(style);
    },
    [webApp]
  );

  const notificationOccurred = useCallback(
    (type: "error" | "success" | "warning"): HapticFeedback | undefined => {
      return webApp?.HapticFeedback.notificationOccurred(type);
    },
    [webApp]
  );

  const selectionChanged = useCallback((): HapticFeedback | undefined => {
    return webApp?.HapticFeedback.selectionChanged();
  }, [webApp]);

  return {
    impactOccurred,

    notificationOccurred,

    selectionChanged,
  };
};

export default useVibration;
