import { FC } from "react";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import { CountCardProps } from "./interface";
const Count: FC<CountCardProps> = ({ count, plusClick, minusClick }) => {
  return (
    <div className={styles.container}>
      <Button
        variant="text"
        onClick={minusClick}
        sx={{ minWidth: 0, minHeight: 0, width: 22, height: 22 }}
      >
        -
      </Button>

      <span className={styles.counter}>{count}</span>

      <Button
        sx={{ minWidth: 0, minHeight: 0, width: 22, height: 22 }}
        onClick={plusClick}
        variant="text"
      >
        +
      </Button>
    </div>
  );
};

export default Count;
