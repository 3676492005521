import SwipeableDrawerComponent from "../../../../components/SwipebleDrawer/SwipeableDrawer";
import { AutorenewRounded } from "@mui/icons-material";
import SliderComponent from "../../../../ui/Slider/Slider";
import SectionHeader from "../../../../components/SectionHeader/SectionHeader";
import styles from "./styles.module.scss";
import CheckboxComponent from "../../../../ui/CheckBox/Checkbox";
import FormSubmitButton from "../../../../ui/FormSubmitButton/FormSubmitButton";
import { FC, useEffect, useState } from "react";
import { FilterFormProps } from "./interface";
import React from "react";
import { useRef } from "react";
import {
  useGetFiltersQuery,
  useGetFoodsQuery,
  useGetGoodsQuery,
  useGetServicesQuery,
} from "../../../../api/productAPI";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../shared/hooks/reduxTypes";
import { productApiSlice } from "../../../../api/productAPI";
import { setPrice } from "../../slice/MenuFilterSlice";
import SlidePrice from "./SlidePrice/SlidePrice";

const FilterForm: FC<FilterFormProps> = ({ open, onClose, onOpen }) => {
  const dispatch = useAppDispatch();
  const {
    data: filterData,
    isLoading,
    isError,
    error,
  } = useGetFiltersQuery(undefined);

  const priceValue = useRef<number>(0);

  const { data: foodListData } = useGetFoodsQuery(undefined);
  const { data: servicesListData } = useGetServicesQuery(undefined);
  const { data: goodsListData } = useGetGoodsQuery(undefined);
  const kitchenTypesSet = useRef(new Set<string>());
  const compoundsSet = useRef(new Set<string>());

  const filterFunction = () => {
    let foodList = foodListData?.data;
    let servicesList = servicesListData?.data;
    let goodsList = goodsListData?.data;
    const price = priceValue.current;
    if (price !== 0) {
      foodList = foodList?.filter((el, index) => {
        return el.price <= price;
      });

      servicesList = servicesList?.filter((el, index) => {
        return el.price <= price;
      });

      goodsList = goodsList?.filter((el, index) => {
        return el.price <= price;
      });
    }

    foodList = foodList?.filter((el) => {
      let compounds = el.compounds.toLowerCase().split(",");

      for (let i = 0; i < compounds.length; i++) {
        let elem = compounds[i];

        if (compoundsSet.current.has(elem)) {
          return false;
        }
      }

      let kitchen = el.kitchen.toLowerCase();

      if (kitchenTypesSet.current.size === 0) {
        return true;
      } else {
        if (kitchenTypesSet.current.has(kitchen)) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    });

    const updateFood = productApiSlice.util.updateQueryData(
      "getFoods",
      undefined,
      (draft) => {
        if (foodList) draft.filtered = foodList;
      }
    );

    dispatch(updateFood);

    const updateServices = productApiSlice.util.updateQueryData(
      "getServices",
      undefined,
      (draft) => {
        if (servicesList) draft.filtered = servicesList;
      }
    );

    dispatch(updateServices);

    const updateGoods = productApiSlice.util.updateQueryData(
      "getGoods",
      undefined,
      (draft) => {
        if (goodsList) draft.filtered = goodsList;
      }
    );

    dispatch(updateGoods);
  };

  const resetFIlters = () => {
    dispatch(setPrice(0));
    kitchenTypesSet.current = new Set();
    compoundsSet.current = new Set();

    const update = productApiSlice.util.updateQueryData(
      "getFilters",
      undefined,
      (draft) => {
        if (draft) {
          for (let i = 0; i < draft.compounds.length; i++) {
            draft.compounds[i].checked = false;
          }

          for (let i = 0; i < draft.kitchentypes.length; i++) {
            draft.kitchentypes[i].checked = false;
          }
        }
      }
    );

    dispatch(update);
  };

  return (
    <>
      <SwipeableDrawerComponent
        header="ФИЛЬТРЫ"
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        leftHeaderButton={<AutorenewRounded />}
        leftHeaderButtonClick={resetFIlters}
      >
        <div className={styles.container}>
          <SlidePrice
            onChange={(num) => {
              priceValue.current = num;
            }}
          />
          <SectionHeader className={styles.header}>ЕДА</SectionHeader>

          <div className={styles.checkboxs}>
            <div className={styles.column}>
              <span className={styles.label}>Тип кухни:</span>

              <ul className={styles.checkboxList}>
                {filterData &&
                  filterData.kitchentypes.map((el, index) => {
                    return (
                      <li>
                        <CheckboxComponent
                          key={el.label}
                          label={el.label}
                          checked={el.checked}
                          onChange={(e) => {}}
                          onClick={() => {
                            if (!filterData.kitchentypes[index].checked) {
                              kitchenTypesSet.current.add(
                                filterData.kitchentypes[
                                  index
                                ].label.toLowerCase()
                              );
                            } else {
                              kitchenTypesSet.current.delete(
                                filterData.kitchentypes[
                                  index
                                ].label.toLowerCase()
                              );
                            }
                            const update = productApiSlice.util.updateQueryData(
                              "getFilters",
                              undefined,
                              (draft) => {
                                draft.kitchentypes[index].checked =
                                  !draft.kitchentypes[index].checked;
                              }
                            );
                            dispatch(update);
                          }}
                        />
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className={styles.column}>
              <span className={styles.label}>В составе нет:</span>

              <ul className={styles.checkboxList}>
                {filterData &&
                  filterData.compounds.map((el, index) => {
                    return (
                      <li key={index}>
                        <CheckboxComponent
                          key={el.label}
                          label={el.label}
                          checked={el.checked}
                          onClick={() => {
                            if (!filterData.compounds[index].checked) {
                              compoundsSet.current.add(
                                filterData.compounds[index].label.toLowerCase()
                              );
                            } else {
                              compoundsSet.current.delete(
                                filterData.compounds[index].label.toLowerCase()
                              );
                            }
                            const update = productApiSlice.util.updateQueryData(
                              "getFilters",
                              undefined,
                              (draft) => {
                                draft.compounds[index].checked =
                                  !draft.compounds[index].checked;
                              }
                            );
                            dispatch(update);
                          }}
                        />
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          <FormSubmitButton
            className={styles.submit}
            onClick={(e) => {
              filterFunction();
              onClose(e);
            }}
            type="button"
          >
            ПРИМЕНИТЬ
          </FormSubmitButton>
        </div>
      </SwipeableDrawerComponent>
    </>
  );
};

export default React.memo(FilterForm);
