import React, { createContext, useContext, useEffect, useState } from "react";
import { useTelegram } from "./useTelegram";
import { useGetUserByTgIDQuery } from "../../api/userAPI";
import { TG_ID } from "../constants/AppOptions";

type Theme = "light" | "dark" | null;

const ThemeContext = createContext<
  | {
      theme: Theme;
      toggleTheme: () => void;
      setValueTheme: (val: "light" | "dark") => void;
    }
  | undefined
>(undefined);

export const UserThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { webApp } = useTelegram();
  const { data: userData, isLoading: userDataLoading } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
  );
  const [theme, setTheme] = useState<Theme>(null);

  useEffect(() => {
    if (webApp && webApp?.colorScheme) {
      setTheme(webApp.colorScheme);
    }
  }, [webApp]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const setValueTheme = (val: "light" | "dark") => {
    setTheme(val);
  };

  useEffect(() => {
    if (userData) {
      if (userData.user_theme === null) {
        setValueTheme(webApp.colorScheme);
        return;
      }

      let themeValue: null | "light" | "dark" = null;

      if (userData.user_theme === "DK") {
        themeValue = "dark";
      }

      if (userData.user_theme === "LI") {
        themeValue = "light";
      }

      if (themeValue) {
        setValueTheme(themeValue);
      }
    }
  }, [userDataLoading]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, setValueTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): {
  theme: Theme;
  toggleTheme: () => void;
  setValueTheme: (val: "light" | "dark") => void;
} => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
