import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuFilterStateModel } from "../models/MenuStateModel";

const initialState: MenuFilterStateModel = {
  price: 0,
  citizens: [],
  compounds: [],
};

const menuFIlterSlice = createSlice({
  name: "menuFilterSlice",
  initialState,
  reducers: {
    setPrice: (state, action: PayloadAction<number>) => {
      state.price = action.payload;
    },

    setStartFilters: (state, action: PayloadAction<any>) => {},

    setCitizens: (state, action: PayloadAction<number>) => {
      state.citizens[action.payload].checked =
        !state.citizens[action.payload].checked;
    },

    setCompounds: (state, action: PayloadAction<number>) => {
      state.citizens[action.payload].checked =
        !state.citizens[action.payload].checked;
    },

    clearForm: (state) => {
      state.price = 0;
      for (let i = 0; i < state.citizens.length; i++) {
        state.citizens[i].checked = false;
      }

      for (let i = 0; i < state.compounds.length; i++) {
        state.compounds[i].checked = false;
      }
    },
  },
});

export default menuFIlterSlice.reducer;

export const {
  clearForm,
  setCompounds,
  setPrice,
  setCitizens,
  setStartFilters,
  
} = menuFIlterSlice.actions;
