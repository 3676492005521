import { Slider, SliderProps } from "@mui/material";
import { FC } from "react";
import styles from "./styles.module.scss";
import { SliderComponentProps } from "./interface";
const SliderComponent: FC<SliderComponentProps> = ({
  min,
  max,
  onChange,
  className,
  name,
  valueChild,
  value,
  step
}) => {
  return (
    <div className={`${className} ${styles.container}`}>
      <div className={styles.label}>
        {name}
        <span className={styles.value}>{valueChild}</span>
      </div>
      <Slider step={step} value={value} min={min} max={max} onChange={onChange}></Slider>
    </div>
  );
};

export default SliderComponent;
