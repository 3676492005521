import AnimatedCard from "../AnimatedCard/AnimatedCard";
import CardTopMediaContainer from "../../../../components/HallCard/CardTopMediaContainer/CardTopMediaContainer";
import CardTopInfoBlock from "../../../../components/HallCard/CardTopInfoBlock/CardTopInfoBlock";
import styles from "./styles.module.scss";
import { useGetHallByIdQuery } from "../../../../api/hallsAPI";
import { FC } from "react";
import { HallOrderProps } from "./interface";
import CounterEditor from "../../../../components/CounterEditor/CounterEditor";
import HallCardName from "../../../../components/HallCard/CardName/HallCardName";
import SkeletonCard from "../../../../ui/SkeletonCard/SkeletonCard";
import {
  useGetUserByTgIDQuery,
  userApiSlice,
  useLazyUpdateCartQuery,
} from "../../../../api/userAPI";
import { TG_ID } from "../../../../shared/constants/AppOptions";
import {
  calcPrice,
  getHourForm,
} from "../../../../shared/functions/hallHelpers";
import { useRef } from "react";
import { useAppDispatch } from "../../../../shared/hooks/reduxTypes";
import { useTelegram } from "../../../../shared/hooks/useTelegram";
import AnimatedHeader from "../AnimatedHeader/AnimatedHeader";
import { useSpring, animated } from "@react-spring/web";
const HallOrder: FC<HallOrderProps> = ({
  data,
  isLoading,
  plusHourClick,
  minusHourClick,
}) => {
  const { webApp } = useTelegram();
  const { data: userData } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
  );
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 300, decay: 300 }, // Настройки анимации
  }));

  if (isLoading) {
    return <SkeletonCard className={styles.skelet} />;
  }

  if (data) {
    if (Object.entries(data).length === 0) {
      return (
        <>
          <AnimatedHeader>ВЫБРАННЫЙ ЗАЛ:</AnimatedHeader>
          <animated.div
            style={{
              textAlign: "center",
              color: "var(--main-text-color)",
              marginTop: 30,
              opacity,
              scale,
            }}
          >
            Ничего не найдено!
          </animated.div>
        </>
      );
    }
    return (
      <>
        <AnimatedHeader>ВЫБРАННЫЙ ЗАЛ:</AnimatedHeader>
        {Object.entries(data)[0][1] && (
          <AnimatedCard
            className={styles.card}
            mediaChild={
              <>
                <CardTopMediaContainer>
                  <CardTopInfoBlock>
                    {Object.entries(data)[0][1].max_people} чел.
                  </CardTopInfoBlock>
                </CardTopMediaContainer>

                <HallCardName name={Object.entries(data)[0][1].name} />
              </>
            }
            image={
              Object.entries(data)[0][1].photo &&
              Object.entries(data)[0][1].photo[0]
                ? Object.entries(data)[0][1].photo[0]
                : ""
            }
          >
            <div className={styles.price}>
              <span> {Object.entries(data)[0][1].price_per_hour} руб/час</span>
            </div>
          </AnimatedCard>
        )}
      </>
    );
  }

  return <></>;
};

export default HallOrder;
