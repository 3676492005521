import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  ReactNode,
} from "react";

interface AnimationContextType {
  animationClassName: string | undefined;
  updateAnimationClassName: (className: string | undefined) => void;
}

// Создаем контекст
const AnimationContext = createContext<AnimationContextType | null>(null);

// Провайдер контекста
interface AnimationProviderProps {
  children: ReactNode; // Определяем тип для children
}

export const AnimationProvider: React.FC<AnimationProviderProps> = ({
  children,
}) => {
  const [animationClassName, setAnimationClassName] = useState<
    string | undefined
  >(undefined);

  const updateAnimationClassName = useCallback(
    (className: string | undefined) => {
      setAnimationClassName(className);
    },
    []
  );

  return (
    <AnimationContext.Provider
      value={{
        animationClassName,
        updateAnimationClassName,
      }}
    >
      {children}
    </AnimationContext.Provider>
  );
};

// Хук для использования контекста
export const usePageAnimationClassName = () => {
  const context = useContext(AnimationContext);
  if (!context) {
    throw new Error(
      "usePageAnimationClassName must be used within an AnimationProvider"
    );
  }
  return context;
};
