import { FC } from "react";
import { BaseComponentInterface } from "../../shared/models/BaseComponentInterface";
import styles from "./styles.module.scss";
import bgImage from "../../shared/assets/BG.webp";
const StartBackgroundContainer: FC<BaseComponentInterface> = ({
  children,
  className,
}) => {
  return (
    <div className={`${styles.superContainer} ${className}`}>
      <div className={styles.background}>
        <img src={bgImage} alt="background" className={styles.background} />
      </div>

      {children}
    </div>
  );
};

export default StartBackgroundContainer;
