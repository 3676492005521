import { ButtonProps } from "@mui/material";
import { FC } from "react";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import { LinkButtonProps } from "./interface";
import { usePageAnimationClassName } from "../../shared/hooks/usePageAnimationClassName";
import useHistory from "../../shared/hooks/useHistory";
import { usePathname } from "../../shared/hooks/usePathname";
const LinkButton: FC<LinkButtonProps> = ({
  children,
  className,
  animationClassName,
  to,
}) => {
  const { updateAnimationClassName, animationClassName: k } =
    usePageAnimationClassName();
  const { navigate } = useHistory();
  const pathname = usePathname();
  return (
    <Button
      disabled={pathname === to}
      onClick={() => {
        requestAnimationFrame(() => {
          navigate(to);
        });

        updateAnimationClassName(animationClassName);
      }}
      className={`${styles.btn} ${className}`}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
