import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL_API } from "../shared/constants/AppOptions";
import {
  FoodListItemApiModel,
  TransformFoodListItemApiModel,
} from "./models/foodListApiModel";
import { ServiceListItemModel } from "./models/serviceListApiModel";
import { GoodListItemApiModel } from "./models/goodListApiModel";
import { BaseApiProductModel } from "./models/BaseApiModuleInterfaces";
import {
  FiltersApiModel,
  TransformFiltersApiModel,
} from "./models/filtersApiModel";
import { BaseCheckboxInterface } from "../shared/models/BaseCheckboxInterface";
import { removeExtraSpaces } from "../shared/functions/removeExtraSpaces";

// Создание API с помощью RTK Query
export const productApiSlice = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (builder) => ({
    getFoods: builder.query<
      BaseApiProductModel<FoodListItemApiModel[]>,
      undefined
    >({
      query: () => "foods",
      transformResponse: (response: FoodListItemApiModel[]) => {
        return {
          data: response,
          filtered: response,
        };
      },
    }),
    getGoods: builder.query<
      BaseApiProductModel<GoodListItemApiModel[]>,
      undefined
    >({
      query: () => "goods",
      transformResponse: (response: GoodListItemApiModel[]) => {
        return {
          data: response,
          filtered: response,
        };
      },
    }),
    getServices: builder.query<
      BaseApiProductModel<ServiceListItemModel[]>,
      undefined
    >({
      query: () => "services",
      transformResponse: (response: ServiceListItemModel[]) => {
        return {
          data: response,
          filtered: response,
        };
      },
    }),
    getFilters: builder.query<TransformFiltersApiModel, undefined>({
      query: () => "/filters",
      transformResponse: (response: FiltersApiModel[]) => {
        let resKitch = removeExtraSpaces(response[0].kitchentypes).split(",");
        let resCompounds = removeExtraSpaces(response[0].compounds).split(",");

        let kitchentypes: BaseCheckboxInterface[] = resKitch.map((el) => {
          return { label: el, checked: false };
        });

        let compounds: BaseCheckboxInterface[] = resCompounds.map((el) => {
          return { label: el, checked: false };
        });

        return { kitchentypes, compounds };
      },
    }),
  }),
});

// Экспорт хука для использования в компонентах
export const {
  useGetFoodsQuery,
  useGetGoodsQuery,
  useGetServicesQuery,
  useGetFiltersQuery,
} = productApiSlice;
