import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterStateModel, SelectValueProps } from "../models/FilterStateModel";
import { SORTOPTIONS } from "../../../shared/constants/SelectOptions";
import { GroupBase, GroupProps } from "react-select";

const initialState: FilterStateModel = {
  capacityValue: 0,
  priceValue: 0,
  date: null,
  hour: 0,
  minute: 0,
  sortValue: SORTOPTIONS[0],
  req_capacityValue: 0,
  req_priceValue: 0,
  req_date: null,
  req_hour: 0,
  req_minute: 0,
  req_sortValue: SORTOPTIONS[0],
};

const filterFormSlice = createSlice({
  name: "filterHallForm",
  initialState,
  reducers: {
    setCapacityValue: (state, action: PayloadAction<number>) => {
      state.capacityValue = action.payload;
    },
    setPriceValue: (state, action: PayloadAction<number>) => {
      state.priceValue = action.payload;
    },
    setDate: (state, action: PayloadAction<string | null>) => {
      state.date = action.payload
    },
    setSort: (state, action: PayloadAction<SelectValueProps>) => {
      state.sortValue = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      const date = new Date(action.payload);
      const selectedHour = date.getHours();
      const selectedMinute = date.getMinutes();

      if (selectedMinute === 0 || selectedMinute === 30) {
        state.hour = selectedHour;
        state.minute = selectedMinute;
        return;
      }
      if (selectedMinute < 30) {
        state.hour = selectedHour;
        state.minute = 30;
        return;
      }
      if (selectedMinute > 30 && selectedMinute < 60) {
        state.hour = selectedHour + 1 >= 24 ? 0 : selectedHour + 1;
        state.minute = 0;
        return;
      }
    },
    setHour: (state, action: PayloadAction<number>) => {
      state.hour = action.payload;
    },
    setMinute: (state, action: PayloadAction<number>) => {
      state.minute = action.payload;
    },
    clearForm: (state) => {
      state.capacityValue = 0;
      state.priceValue = 0;
      state.date = "";
    },
    setReqValues: (state) => {
      state.req_capacityValue = state.capacityValue;
      state.req_priceValue = state.priceValue;
      state.req_date = state.date;
      state.req_hour = state.hour;
      state.req_minute = state.minute;
      state.req_sortValue = state.sortValue;
    },
  },
});

export const {
  setCapacityValue,
  setPriceValue,
  setDate,
  clearForm,
  setSort,
  setHour,
  setMinute,
  setStartDate,
  setReqValues
} = filterFormSlice.actions;

export default filterFormSlice.reducer;
